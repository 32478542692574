import Vue from "vue";
// import App from "./App.vue";
import App from "./Reservations.vue";
import router from "./router";
import store from "@/store";
import "./routeNavigation";
import "element-ui/lib/theme-chalk/index.css";
import * as filters from "@/utils/filters"; //全局过滤器
import {
  // Button,
  // Dropdown,
  // DropdownMenu,
  // DropdownItem,
  // Checkbox,
  // CheckboxGroup,
  Loading,
  DatePicker,
  // Radio,
  // Alert,
  // Select,
  // Option
} from "element-ui";
// Vue.use(Button);
// Vue.use(Dropdown);
// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
// Vue.use(Checkbox);
// Vue.use(CheckboxGroup);
Vue.use(Loading);
Vue.use(DatePicker);
// Vue.use(Radio);
// Vue.use(Alert);
// Vue.use(Select);
// Vue.use(Option);
// 组件日文化
import ja from "element-ui/lib/locale/lang/ja";
import locale from "element-ui/lib/locale";

locale.use(ja);
//注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
