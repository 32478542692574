<template>
  <div id="page reservations">
    <loading />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import loading from "@/components/loading/loading.vue";
export default {
  computed: {
    ...mapState(["IsPC", "loginError"]),
  },
  name: "App",
  components: { loading },
  created() {
    this.$store.commit("checkDevice");
  },
};
</script>

<style lang="scss">
@import "@/style/bundle.scss";
#app {
}
</style>
